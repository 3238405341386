/**
 * Is email ?
 * @param {*} email
 */
module.exports.isEmail = (email) => {
	const re = /\S+@\S+\.\S+/;
	return re.test(email);
};

module.exports.isProduction = () =>
	process.env.NODE_ENV === 'production' || process.env.VERCEL_ENV === 'production';
module.exports.isDevelopment = () =>
	process.env.NODE_ENV === 'development' || process.env.VERCEL_ENV === 'development';
module.exports.isPreview = () =>
	process.env.NODE_ENV === 'preview' || process.env.VERCEL_ENV === 'preview';
module.exports.getEnvironement = () => process.env.NODE_ENV || process.env.VERCEL_ENV;

module.exports.parsePortfolio = ({ sys, fields }) => ({
	id: sys.id,
	locale: sys.locale,
	title: fields.title,
	slug: fields.slug,
	description: fields.shortDescription,
	link: fields.link,
	image: fields.image.fields.file.url,
	tech: fields.techStack.stack
});

module.exports.parseArticle = ({
	sys: { id, createdAt, updatedAt, locale },
	fields: { title, slug, description, heroImage, body, author, publishDate, tags, duration }
}) => ({
	id,
	locale,
	createdAt,
	updatedAt,
	title,
	slug,
	description,
	image: heroImage.fields.file,
	body,
	author,
	duration,
	publishDate,
	tags
});

module.exports.parseSocial = ({ sys, fields: { username, name, baseUrl, icon } }) => ({
	id: sys.id,
	locale: sys.locale,
	username,
	name,
	link: `${baseUrl}/${username}`,
	icon
});

module.exports.selectAllTags = (articles) => {
	const flatArticles = articles.map(({ fields: { tags } }) => tags).flat();

	const mappedArray = mapArray(flatArticles);
	let result = [];

	mappedArray.forEach((value, key) => {
		if (key) {
			result.push({ tag: key, count: value });
		}
	});

	return result;
};

/**
 * Use map.keys() to get unique elements
 * Use map.values() to get the occurrences
 * Use map.entries() to get the pairs [element,
 */
const mapArray = (arr) => arr.reduce((acc, e) => acc.set(e, (acc.get(e) || 0) + 1), new Map());

module.exports.mapArray = mapArray;

module.exports.parseImageURL = (url) => {
	return url.slice(0, 2) === '//' ? `https://${url.slice(2, url.length)}` : url;
};

/**
 * nextjs metric
 * @param {object} metric
 */
module.exports.handleWebVitals = (metric) => {
	return {
		...metric,
		startTime: Math.round(metric.startTime),
		value: Math.round(metric.value)
	};
};

module.exports.prepObjectKeys = (headers) => {
	const keyValues = {};
	Object.keys(headers).map((key) => {
		const newKey = key.replace(/-/g, '_');
		keyValues[newKey] = headers[key];
	});

	return keyValues;
};

/**
 * Trunket
 */
module.exports.truncate = (str, n) => {
	return str.length > n ? str.substring(n - 1) + '...' : str;
};

/**
 * Return new array with only unique values (no redencency)
 * @param {array} items
 * @returns
 */
module.exports.getUniqItemsArray = (items) => [...new Set(items)];
